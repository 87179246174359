.bg-primary {
  background-color: #53AAE0 !important;
}

.text-primary {
  color: #53AAE0 !important;
}

.brand-logo {
  height: 80px;
  margin-top: 25px !important;
  position: absolute;
  z-index: 2;
}

.footer-logo {
  width: 40%;
  height: auto;
  text-align: center;

}

a:link {
  text-decoration: none;
}


#cabecera {
  background-image: url("./img/Portada_Ministerio.gif");
  background-size: 100%;
  opacity: 0.5;
  position: relative;
  height: 120px;
}

#contFooter {
  margin-top: 8% !important;
}

.bailar {
  animation: shake 0.85s cubic-bezier(.36, .07, .19, .97) both;
}

.custom-button {
  background-color: transparent; /* Fondo transparente */
  color: #53AAE0; /* Color del texto */
  background-color: white;
  border-radius: 100%; /* Esquinas redondeadas */
  height: auto;
  width: 120px; /* Ancho fijo */
  padding: 10px; /* Espaciado interno reducido */
  display: flex; /* Usar flexbox */
  flex-direction: column; /* Coloca imagen y texto en columna */
  align-items: center; /* Centra los elementos horizontalmente */
}

.custom-button:hover,
.custom-button:focus
 {
  border-color: #53AAE0; /* Color celeste al pasar el ratón o al estar seleccionado */
  color: white; /* Cambia el color del texto al blanco */
}

.small-image {
  width: 70px; /* Tamaño de la imagen */
  height: 70px; /* Tamaño de la imagen */
  object-fit: contain; /* Mantiene la proporción de la imagen */
}

.floating-button {
  position: fixed;
  bottom: 150px; /* Altura inicial */
  right: 60px;
  z-index: 1000;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px; /* Ajusta según sea necesario */
}

.edubotFrame {
  width: 100%;
  height: 500px;
  border: none;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 25px;
}

.button-text {
  margin-top: 5px; /* Espacio entre la imagen y el texto */
  color: #53AAE0; /* Color del texto */
  font-size: 13px; /* Tamaño del texto */
  text-align: center; /* Centrar el texto */
}


/* Media query para móviles */
@media (max-width: 768px) {
  .floating-button {
    bottom: 15px; /* Mueve el botón más abajo en móviles */
    right: 30px; /* Ajusta la posición */
  }
  .small-image {
    width: 80px !important;
    height: 80px; /* Tamaño de la imagen para móviles */
    object-fit: contain; /* Mantiene la proporción de la imagen */
  }
  .popup-content {
   
    margin-bottom: 15%;
  }


  .custom-button {
 
    width:  130px; /* Ancho fijo */

  }
  .edubotFrame {
 
    height: 300px;
  
  }
  
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 100,
  'GRAD' 0,
  'opsz' 24
}

@keyframes shake {
  10%, 90% {
      transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
      transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
      transform: translate3d(4px, 0, 0);
  }
}

@media only screen and (max-width: 1000px) {

.menu {
  padding: 2em 10%; /* Reducir el padding horizontal */
}

  .footer-logo {
      width: 80%;
      height: auto;
  }

  .brand-logo {
      height: 54px;
  }
}

@media only screen and (max-width: 700px) {
  .footer-logo {
      width: 100%;
      height: auto;
  }

  #img-fast {
      display: none;
  }
}