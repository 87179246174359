/* Contenedor principal del carrusel */
.slider-container {
    width: 100%; /* Mantener el 100% de ancho */

    position: relative;
}

/* Estilo del carrusel (contenedor) */
.slider {

    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white; /* Fondo blanco para mejorar la visibilidad */
}

/* Contenido deslizante */
.slider-content {
    display: flex;
    width: 100%;
    transition: transform 1.2s ease-in-out; /* Transición suave al cambiar de imagen */
}

/* Estilo de cada item dentro del carrusel */
.slider-item {
    flex: 0 0 100%; /* Cada item ocupa el 100% del ancho */
    display: flex;
    padding: 20px;
    box-sizing: border-box; /* El padding no afecta el tamaño total */
    justify-content: center; /* Centrar el contenido */
    align-items: center;
}

/* Información de la categoría (título e imagen) */
.category-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
}

/* Imagen de la categoría */
.category-info img {
    max-width: 100%; /* Asegura que las imágenes no se desborden */
    height: auto;
    margin-top: 15px;
}

/* Detalles de la categoría (descripción y botón) */
.category-details {
    flex: 1;
    padding-left: 20px;
    text-align: left;
}



/* Hover en el botón */
.category-details button:hover {
    background-color: #53AAE0;
    border: 1px solid #53AAE0;
}
.category-card:hover {

    border-color: #53AAE0;
   
}
  
/* Contenedor de la tarjeta de categoría */
.category-card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra todos los elementos dentro de la tarjeta */
    justify-content: center;
    text-align: center;
    padding: 15px; /* Agrega un poco de padding para mejorar el diseño */
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white;
}

/* Imagen de la categoría */
.category-image {
    height: auto;
    width: 100px !important;
    max-width: 100%; /* Asegura que la imagen no desborde el contenedor */
    margin-bottom: 1px; /* Un poco de espacio entre la imagen y el título */
}

.buscar-image {
    height: auto;
    width: 130px !important;
    max-width: 100%; /* Asegura que la imagen no desborde el contenedor */
  
}

/* Estilo del contenedor de la categoría debajo de la imagen */
.category-card-body {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el texto debajo de la imagen */
    justify-content: center;
}

/* Título de la categoría */
.category-card-body h5 {
    margin: 0; /* Elimina márgenes extra */
    font-size: 1.2rem;

}

/* Detalles de la categoría (más información) */
.details {
    margin-top: 20px;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
}

/* Estilo de los botones de navegación del carrusel */
.slider-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

/* Estilo de los botones de navegación */
.slider-nav button {
    background-color: rgba(180, 177, 177, 0.199);
    color: white;
    font-size: 24px;
    border: none;
    padding: 10px;
    cursor: pointer;
}

/* Hover en los botones de navegación */
.slider-nav button:hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: grey;
}

/* Agregar sombra y detalles para los botones de navegación */
.slider-nav button:focus {
    outline: none;
}

/* Estilos generales para mejorar la accesibilidad y estética */
.slider-item h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.slider-item p {
    font-size: 1rem;
}

/* Asegurar que el texto no se desborde de los contenedores */
.category-details p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 100px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
    .slider-container {
        padding-top: 10px;
    }

    .slider-item {
        padding: 10px;
    }

    .category-info img {
        max-width: 80%;
    }

    .category-details {
        padding-left: 10px;
    }

    .category-details button {
        width: 100%;
        margin-top: 10px;
    }

    .slider-nav button {
        font-size: 20px;
        padding: 8px;
    }
}

/* Media query para móviles */
@media (max-width: 480px) {
    .category-info img {
        max-width: 70%;
    }

    .slider-item {
        padding: 5px;
    }

    .category-details {
        padding-left: 5px;
    }

    .slider-nav button {
        font-size: 18px;
        padding: 6px;
    }
}